import emailjs from "@emailjs/browser";
import { useEffect, useRef, useState } from "react";
import React from "react";
import Head from "./Head";
import omrahp from "./assets/1.jpg";
import o1 from "./assets/omrahp1.jpg";
import h from "./assets/h.jpg";
// import v1 from "./assets/inv1.jpg";

export const BookingOmrah = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tel: "",
    codeTel: "+212", // Default value
    purpose: "omrah", // Default value
    message: "",
    hotel: "",
    roomType: "",
  });

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zx1ebxt",
        "template_gmn4v6o",
        e.target,
        "DPpmV3XbxNyPUT3_P"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          setShowSuccessPopup(true); // Show the popup

          setTimeout(() => {
            setShowSuccessPopup(false); // Hide the popup after 3 seconds
          }, 3000);

          setFormData({
            name: "",
            email: "",
            tel: "",
            codeTel: "+212", // Reset to default
            purpose: "omrah", // Reset to default
            message: "",
            hotel: "",
            roomType: "",
          });
        },
        (error) => {
          console.error("Email sending failed:", error.text);
          setError("Failed to send message. Please try again later."); // Display error to user
        }
      );
  };

  return (
    <>
      <div className="resimgc">
        <img src={omrahp} alt="contact" className="resimg" />
      </div>
      <Head />

      <h2 className="contactez" id="contact">
        RESERVEZ
      </h2>
      <div className="contact">
        <form onSubmit={sendEmail} ref={form}>
          <label htmlFor="name">NOM:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="VOTRE NOM COMPLET"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <br />
          <label htmlFor="email">EMAIL:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="VOTRE EMAIL"
            value={formData.email}
            onChange={handleChange}
          />
          <br />
          <label htmlFor="tel">NUMERO DE TELEPHONE:</label>
          <div style={{ display: "flex", flexDirection: "row", width: "111%" }}>
            <select
              style={{ width: "25%" }}
              name="codeTel"
              value={formData.codeTel}
              onChange={handleChange}
              required
            >
              <option value="+212">(+212) Maroc</option>
              <option value="+213">(+213) Algérie</option>
              <option value="+216">(+216) Tunisie</option>
              <option value="+218">(+218) Libye</option>
              <option value="+20">(+20) Égypte</option>
              <option value="+30">(+30) Grèce</option>
              <option value="+31">(+31) Pays-Bas</option>
              <option value="+32">(+32) Belgique</option>
              <option value="+33">(+33) France</option>
              <option value="+34">(+34) Espagne</option>
              <option value="+39">(+39) Italie</option>
              <option value="+44">(+44) Royaume-Uni</option>
              <option value="+49">(+49) Allemagne</option>
              <option value="+1">(+1) États-Unis/Canada</option>
              <option value="+52">(+52) Mexique</option>
              <option value="+54">(+54) Argentine</option>
              <option value="+55">(+55) Brésil</option>
            </select>
            <input
              type="text"
              className="destinatf"
              id="tel"
              name="tel"
              value={formData.tel}
              pattern="[0-9]*"
              title="Le numéro de téléphone doit contenir uniquement des chiffres"
              onChange={handleChange}
              placeholder="VOTRE NUMERO DE TELEPHONE"
              style={{ width: "65%" }}
              required
            />
          </div>
          <br />
          <label htmlFor="purpose">DESTINATIONS:</label>
          <select
            className="destinatf"
            id="purpose"
            name="purpose"
            value={formData.purpose}
            onChange={handleChange}
            required
          >
            <option value="omrah de Ramadan">OMRAH</option>
          </select>
          <br />
          <label htmlFor="hotel">HOTEL:</label>
          <select
            id="hotel"
            name="hotel"
            value={formData.hotel}
            onChange={handleChange}
            required
          >
            <option value="ABRAJ AL KISWA">NAMA AL MAWADA</option>
            <option value="SARAYA DIAFA">MANARAT GROL</option>
            <option value="ANJUM">AL YOUSSR ROYAL</option>
            <option value="SUISSOTEL">NOUR SALAH</option>
          </select>
          <br />
          <label htmlFor="roomType">CHAMBRE:</label>
          <select
            id="roomType"
            name="roomType"
            value={formData.roomType}
            onChange={handleChange}
            required
          >
            <option value="double">Double</option>
            <option value="pour 3 personnes">pour 3 personnes</option>
            <option value="pour 4 personnes">pour 4 personnes</option>
            <option value="pour 5 personnes">pour 5 personnes</option>
            <option value="pour 6 personnes">pour 6 personnes</option>
          </select>
          <br />
          <label htmlFor="message">MESSAGE:</label>
          <textarea
            name="message"
            id="message"
            placeholder="ECRIVEZ VOTRE MESSAGE"
            value={formData.message}
            onChange={handleChange}
            style={{ borderRadius: "7px" }}
          ></textarea>
          <input type="submit" value="Envoyez" className="button" />
        </form>
      </div>

      {showSuccessPopup && (
        <div className="success-popup">
          <p>
            Message envoyé avec succès. Nous vous répondrons dès que possible.
          </p>
        </div>
      )}

      {error && (
        <div className="error-popup">
          <p>{error}</p>
        </div>
      )}
    </>
  );
};

//
export const BookingOmrah1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tel: "",
    codeTel: "+212", // Default value
    purpose: "omrah", // Default value
    message: "",
    hotel: "",
    roomType: "",
  });

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zx1ebxt",
        "template_gmn4v6o",
        e.target,
        "DPpmV3XbxNyPUT3_P"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          setShowSuccessPopup(true); // Show the popup

          setTimeout(() => {
            setShowSuccessPopup(false); // Hide the popup after 3 seconds
          }, 3000);

          setFormData({
            name: "",
            email: "",
            tel: "",
            codeTel: "+212", // Reset to default
            purpose: "omrah", // Reset to default
            message: "",
            hotel: "",
            roomType: "",
          });
        },
        (error) => {
          console.error("Email sending failed:", error.text);
          setError("Failed to send message. Please try again later."); // Display error to user
        }
      );
  };

  return (
    <>
      <div className="resimgc">
        <img src={o1} alt="contact" className="resimg" />
      </div>
      <Head />

      <h2 className="contactez" id="contact">
        RESERVEZ
      </h2>
      <div className="contact">
        <form onSubmit={sendEmail} ref={form}>
          <label htmlFor="name">NOM:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="VOTRE NOM COMPLET"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <br />
          <label htmlFor="email">EMAIL:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="VOTRE EMAIL"
            value={formData.email}
            onChange={handleChange}
          />
          <br />
          <label htmlFor="tel">NUMERO DE TELEPHONE:</label>
          <div style={{ display: "flex", flexDirection: "row", width: "111%" }}>
            <select
              style={{ width: "25%" }}
              name="codeTel"
              value={formData.codeTel}
              onChange={handleChange}
              required
            >
              <option value="+212">(+212) Maroc</option>
              <option value="+213">(+213) Algérie</option>
              <option value="+216">(+216) Tunisie</option>
              <option value="+218">(+218) Libye</option>
              <option value="+20">(+20) Égypte</option>
              <option value="+30">(+30) Grèce</option>
              <option value="+31">(+31) Pays-Bas</option>
              <option value="+32">(+32) Belgique</option>
              <option value="+33">(+33) France</option>
              <option value="+34">(+34) Espagne</option>
              <option value="+39">(+39) Italie</option>
              <option value="+44">(+44) Royaume-Uni</option>
              <option value="+49">(+49) Allemagne</option>
            </select>
            <input
              type="text"
              className="destinatf"
              id="tel"
              name="tel"
              value={formData.tel}
              pattern="[0-9]*"
              title="Le numéro de téléphone doit contenir uniquement des chiffres"
              onChange={handleChange}
              placeholder="VOTRE NUMERO DE TELEPHONE"
              style={{ width: "65%" }}
              required
            />
          </div>
          <br />
          <label htmlFor="purpose">DESTINATIONS:</label>
          <select
            className="destinatf"
            id="purpose"
            name="purpose"
            value={formData.purpose}
            onChange={handleChange}
            required
          >
            <option value="omrah">OMRAH</option>
          </select>
          <br />
          <label htmlFor="hotel">HOTEL:</label>
          <select
            id="hotel"
            name="hotel"
            value={formData.hotel}
            onChange={handleChange}
            required
          >
            <option value="ABRAJ AL KISWA">ABRAJ AL KISWA</option>
            <option value="SARAYA DIAFA">SARAYA DIAFA</option>
            <option value="ANJUM">ANJUM</option>
            <option value="SUISSOTEL">SUISSOTEL</option>
          </select>
          <br />
          <label htmlFor="roomType">CHAMBRE:</label>
          <select
            id="roomType"
            name="roomType"
            value={formData.roomType}
            onChange={handleChange}
            required
          >
            <option value="double">Double</option>
            <option value="pour 3 personnes">pour 3 personnes</option>
            <option value="pour 4 personnes">pour 4 personnes</option>
            <option value="pour 5 personnes">pour 5 personnes</option>
          </select>
          <br />
          <label htmlFor="message">MESSAGE:</label>
          <textarea
            name="message"
            id="message"
            placeholder="ECRIVEZ VOTRE MESSAGE"
            value={formData.message}
            onChange={handleChange}
            style={{ borderRadius: "7px" }}
          ></textarea>
          <input type="submit" value="Envoyez" className="button" />
        </form>
      </div>

      {showSuccessPopup && (
        <div className="success-popup">
          <p>
            Message envoyé avec succès. Nous vous répondrons dès que possible.
          </p>
        </div>
      )}

      {error && (
        <div className="error-popup">
          <p>{error}</p>
        </div>
      )}
    </>
  );
};
//
export const BookingHadj = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tel: "",
    codeTel: "+212", // Default value
    purpose: "omrah", // Default value
    message: "",
    hotel: "",
    roomType: "",
  });

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_zx1ebxt",
        "template_gmn4v6o",
        e.target,
        "DPpmV3XbxNyPUT3_P"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          setShowSuccessPopup(true); // Show the popup

          setTimeout(() => {
            setShowSuccessPopup(false); // Hide the popup after 3 seconds
          }, 3000);

          setFormData({
            name: "",
            email: "",
            tel: "",
            codeTel: "+212", // Reset to default
            purpose: "omrah", // Reset to default
            message: "",
            hotel: "",
            roomType: "",
          });
        },
        (error) => {
          console.error("Email sending failed:", error.text);
          setError("Failed to send message. Please try again later."); // Display error to user
        }
      );
  };

  return (
    <>
      <div className="resimgc">
        <img src={h} alt="contact" className="resimg" />
      </div>
      <Head />

      <h2 className="contactez" id="contact">
        RESERVEZ
      </h2>
      <div className="contact">
        <form onSubmit={sendEmail} ref={form}>
          <label htmlFor="name">NOM:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="VOTRE NOM COMPLET"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <br />
          <label htmlFor="email">EMAIL:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="VOTRE EMAIL"
            value={formData.email}
            onChange={handleChange}
          />
          <br />
          <label htmlFor="tel">NUMERO DE TELEPHONE:</label>
          <div style={{ display: "flex", flexDirection: "row", width: "111%" }}>
            <select
              style={{ width: "25%" }}
              name="codeTel"
              value={formData.codeTel}
              onChange={handleChange}
              required
            >
              <option value="+212">(+212) Maroc</option>
              <option value="+213">(+213) Algérie</option>
              <option value="+216">(+216) Tunisie</option>
              <option value="+218">(+218) Libye</option>
              <option value="+20">(+20) Égypte</option>
              <option value="+30">(+30) Grèce</option>
              <option value="+31">(+31) Pays-Bas</option>
              <option value="+32">(+32) Belgique</option>
              <option value="+33">(+33) France</option>
              <option value="+34">(+34) Espagne</option>
              <option value="+39">(+39) Italie</option>
              <option value="+44">(+44) Royaume-Uni</option>
              <option value="+49">(+49) Allemagne</option>
            </select>
            <input
              type="text"
              className="destinatf"
              id="tel"
              name="tel"
              value={formData.tel}
              pattern="[0-9]*"
              title="Le numéro de téléphone doit contenir uniquement des chiffres"
              onChange={handleChange}
              placeholder="VOTRE NUMERO DE TELEPHONE"
              style={{ width: "65%" }}
              required
            />
          </div>
          <br />
          <label htmlFor="purpose">DESTINATION:</label>
          <select
            className="destinatf"
            id="purpose"
            name="purpose"
            value={formData.purpose}
            onChange={handleChange}
            required
          >
            <option value="HADJ">HADJ</option>
          </select>
          <br />
          <label htmlFor="hotel">HOTEL:</label>
          <select
            id="hotel"
            name="hotel"
            value={formData.hotel}
            onChange={handleChange}
            required
          >
            <option value="DAR AL KHALIL">DAR AL KHALIL</option>
            <option value="ABRAJ AL MERIDIEN">ABRAJ AL MERIDIEN</option>
            <option value="ZOUWAR AL BAYT">ZOUWAR AL BAYT</option>
            <option value="MAKARIM AJYAD">MAKARIM AJYAD</option>
            <option value="SUISS MEKKAH">SUISS MEKKAH</option>
          </select>
          <br />
          <label htmlFor="roomType">CHAMBRE:</label>
          <select
            id="roomType"
            name="roomType"
            value={formData.roomType}
            onChange={handleChange}
            required
          >
            <option value="double">Double</option>
            <option value="pour 3 personnes">pour 3 personnes</option>
            <option value="pour 4 personnes">pour 4 personnes</option>
          </select>
          <br />
          <label htmlFor="message">MESSAGE:</label>
          <textarea
            name="message"
            id="message"
            placeholder="ECRIVEZ VOTRE MESSAGE"
            value={formData.message}
            onChange={handleChange}
            style={{ borderRadius: "7px" }}
          ></textarea>
          <input type="submit" value="Envoyez" className="button" />
        </form>
      </div>

      {showSuccessPopup && (
        <div className="success-popup">
          <p>
            Message envoyé avec succès. Nous vous répondrons dès que possible.
          </p>
        </div>
      )}

      {error && (
        <div className="error-popup">
          <p>{error}</p>
        </div>
      )}
    </>
  );
};
export const BookingVisa1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tel: "",
    codeTel: "+212", // Default value
    message: "",
    pays: "",
  });

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_xwo1nkg",
        "template_173o5pm",
        e.target,
        "u1vV_5wxERJS3ecIC"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          setShowSuccessPopup(true); // Show the popup

          setTimeout(() => {
            setShowSuccessPopup(false); // Hide the popup after 3 seconds
          }, 3000);

          setFormData({
            name: "",
            email: "",
            tel: "",
            codeTel: "+212", // Reset to default
            message: "",
            pays: "",
          });
        },
        (error) => {
          console.error("Email sending failed:", error.text);
          // Optionally set error state here for user feedback
        }
      );
  };

  return (
    <>
      <Head />
      {/* <div>
        <img src={v1} alt="contact" className="contactimg" />
      </div> */}
      <h2 className="contactez" id="contact">
        RESERVEZ
      </h2>
      <div className="contact">
        <form onSubmit={sendEmail} ref={form}>
          <label htmlFor="name">NOM:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="VOTRE NOM COMPLET"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <br />
          <label htmlFor="email">EMAIL:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="VOTRE EMAIL"
            value={formData.email}
            onChange={handleChange}
          />
          <br />
          <label htmlFor="tel">NUMERO DE TELEPHONE:</label>
          <div style={{ display: "flex", flexDirection: "row", width: "111%" }}>
            <select
              style={{ width: "23%" }}
              name="codeTel"
              value={formData.codeTel}
              onChange={handleChange}
              required
            >
              <option value="+212">(+212) Maroc</option>
              <option value="+213">(+213) Algérie</option>
              <option value="+216">(+216) Tunisie</option>
              <option value="+218">(+218) Libye</option>
              <option value="+20">(+20) Égypte</option>
              <option value="+30">(+30 ) Grèce</option>
              <option value="+31">(+31 ) Pays-Bas</option>
              <option value="+32">(+32 ) Belgique</option>
              <option value="+33">(+33 ) France</option>
              <option value="+34">(+34 ) Espagne</option>
              <option value="+39">(+39 ) Italie</option>
              <option value="+44">(+44 ) Royaume-Uni</option>
              <option value="+49">(+49 ) Allemagne</option>
              <option value="+1">(+1 ) États-Unis/Canada</option>
              <option value="+52">(+52 ) Mexique</option>
              <option value="+54">(+54 ) Argentine</option>
              <option value="+55">(+55 ) Brésil</option>{" "}
            </select>
            <input
              type="text"
              id="tel"
              name="tel"
              value={formData.tel}
              pattern="\d*"
              title="Le numéro de téléphone doit contenir uniquement des chiffres"
              onChange={handleChange}
              placeholder="VOTRE NUMERO DE TELEPHONE"
              style={{ width: "67%" }}
              required
            />
          </div>
          <br />
          <label htmlFor="pays">PAYS:</label>
          <select
            id="pays"
            name="pays"
            value={formData.pays}
            onChange={handleChange}
            required
          >
            <option value="visa EMIRATE ">EMIRATE</option>
            <option value="visa EGYPT  ">EGYPT</option>
            <option value="visa SAUDIA ">SAUDIA</option>
            <option value="visa EMIRATE ">BAHRAïN</option>
            <option value="visa EGYPT  ">QUATAR</option>
            <option value="VISA CHINA">CHINA</option>
            <option value="VISA SINGAPORE">SINGAPORE</option>
            <option value="visa ARMENIA">ARMENIA</option>
            <option value="visa ARMENIA">ALBANIA</option>
            <option value="visa ARMENIA">JAPAN</option>
            <option value="visa ARMENIA">KUWAIT</option>
            <option value="visa ARMENIA">OMAN</option>




          </select>
          <br />
          <br />
          <label htmlFor="message">MESSAGE:</label>
          <textarea
            name="message"
            id="message"
            placeholder="ECRIVEZ VOTRE MESSAGE"
            value={formData.message}
            onChange={handleChange}
            style={{ borderRadius: "7px" }}
          ></textarea>
          <input type="submit" value="Envoyez" className="button" />
        </form>
      </div>

      {showSuccessPopup && (
        <div className="success-popup">
          <p>
            Message envoyé avec succès. Nous vous répondrons dès que possible.
          </p>
        </div>
      )}
    </>
  );
};

export const ISTANBUL = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tel: "",
    codeTel: "+212", // Default value
    message: "",
    pays: "",
  });

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_xwo1nkg",
        "template_173o5pm",
        e.target,
        "u1vV_5wxERJS3ecIC"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          setShowSuccessPopup(true); // Show the popup

          setTimeout(() => {
            setShowSuccessPopup(false); // Hide the popup after 3 seconds
          }, 3000);

          setFormData({
            name: "",
            email: "",
            tel: "",
            codeTel: "+212", // Reset to default
            message: "",
            pays: "",
          });
        },
        (error) => {
          console.error("Email sending failed:", error.text);
          // Optionally set error state here for user feedback
        }
      );
  };

  return (
    <>
      <Head />
      {/* <div>
        <img src={v1} alt="contact" className="contactimg" />
      </div> */}
      <h2 className="contactez" id="contact">
        RESERVEZ
      </h2>
      <div className="contact">
        <form onSubmit={sendEmail} ref={form}>
          <label htmlFor="name">NOM:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="VOTRE NOM COMPLET"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <br />
          <label htmlFor="email">EMAIL:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="VOTRE EMAIL"
            value={formData.email}
            onChange={handleChange}
          />
          <br />
          <label htmlFor="tel">NUMERO DE TELEPHONE:</label>
          <div style={{ display: "flex", flexDirection: "row", width: "111%" }}>
            <select
              style={{ width: "23%" }}
              name="codeTel"
              value={formData.codeTel}
              onChange={handleChange}
              required
            >
              <option value="+212">(+212) Maroc</option>
              <option value="+213">(+213) Algérie</option>
              <option value="+216">(+216) Tunisie</option>
              <option value="+218">(+218) Libye</option>
              <option value="+20">(+20) Égypte</option>
              <option value="+30">(+30 ) Grèce</option>
              <option value="+31">(+31 ) Pays-Bas</option>
              <option value="+32">(+32 ) Belgique</option>
              <option value="+33">(+33 ) France</option>
              <option value="+34">(+34 ) Espagne</option>
              <option value="+39">(+39 ) Italie</option>
              <option value="+44">(+44 ) Royaume-Uni</option>
              <option value="+49">(+49 ) Allemagne</option>
            </select>
            <input
              type="text"
              id="tel"
              name="tel"
              value={formData.tel}
              pattern="\d*"
              title="Le numéro de téléphone doit contenir uniquement des chiffres"
              onChange={handleChange}
              placeholder="VOTRE NUMERO DE TELEPHONE"
              style={{ width: "67%" }}
              required
            />
          </div>
          <br />
          <label htmlFor="pays">PAYS:</label>
          <select
            id="pays"
            name="pays"
            value={formData.pays}
            onChange={handleChange}
            required
          >
            <option value=" offre_turquie(istanbul) ">turquie</option>
          </select>
          <br />
          <br />
          <label htmlFor="message">MESSAGE:</label>
          <textarea
            name="message"
            id="message"
            placeholder="ECRIVEZ VOTRE MESSAGE"
            value={formData.message}
            onChange={handleChange}
            style={{ borderRadius: "7px" }}
          ></textarea>
          <input type="submit" value="Envoyez" className="button" />
        </form>
      </div>

      {showSuccessPopup && (
        <div className="success-popup">
          <p>
            Message envoyé avec succès. Nous vous répondrons dès que possible.
          </p>
        </div>
      )}
    </>
  );
};

export const Flights = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tel: "",
    codeTel: "+212", // Default value
    message: "",
    pays: "",
  });

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_xwo1nkg",
        "template_173o5pm",
        e.target,
        "u1vV_5wxERJS3ecIC"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          setShowSuccessPopup(true); // Show the popup

          setTimeout(() => {
            setShowSuccessPopup(false); // Hide the popup after 3 seconds
          }, 3000);

          setFormData({
            name: "",
            email: "",
            tel: "",
            codeTel: "+212", // Reset to default
            message: "",
            pays: "",
          });
        },
        (error) => {
          console.error("Email sending failed:", error.text);
          // Optionally set error state here for user feedback
        }
      );
  };

  return (
    <>
      <Head />
      {/* <div>
        <img src={v1} alt="contact" className="contactimg" />
      </div> */}
      <h2 className="contactez" id="contact">
        RESERVEZ
      </h2>
      <div className="contact">
        <form onSubmit={sendEmail} ref={form}>
          <label htmlFor="name">NOM:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="VOTRE NOM COMPLET"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <br />
          <label htmlFor="email">EMAIL:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="VOTRE EMAIL"
            value={formData.email}
            onChange={handleChange}
          />
          <br />
          <label htmlFor="tel">NUMERO DE TELEPHONE:</label>
          <div style={{ display: "flex", flexDirection: "row", width: "111%" }}>
            <select
              style={{ width: "23%" }}
              name="codeTel"
              value={formData.codeTel}
              onChange={handleChange}
              required
            >
              <option value="+212">(+212) Maroc</option>
              <option value="+213">(+213) Algérie</option>
              <option value="+216">(+216) Tunisie</option>
              <option value="+218">(+218) Libye</option>
              <option value="+20">(+20) Égypte</option>
              <option value="+30">(+30 ) Grèce</option>
              <option value="+31">(+31 ) Pays-Bas</option>
              <option value="+32">(+32 ) Belgique</option>
              <option value="+33">(+33 ) France</option>
              <option value="+34">(+34 ) Espagne</option>
              <option value="+39">(+39 ) Italie</option>
              <option value="+44">(+44 ) Royaume-Uni</option>
              <option value="+49">(+49 ) Allemagne</option>
            </select>
            <input
              type="text"
              id="tel"
              name="tel"
              value={formData.tel}
              pattern="\d*"
              title="Le numéro de téléphone doit contenir uniquement des chiffres"
              onChange={handleChange}
              placeholder="VOTRE NUMERO DE TELEPHONE"
              style={{ width: "67%" }}
              required
            />
          </div>
          <br />
          <label htmlFor="pays">PAYS DE DESTINATION:</label>
          <select
            id="pays"
            name="pays"
            value={formData.pays}
            onChange={handleChange}
            required
          >
            <option value="flight_afghanistan">Afghanistan</option>
            <option value="flight_albania">Albania</option>
            <option value="flight_algeria">Algeria</option>
            <option value="flight_andorra">Andorra</option>
            <option value="flight_angola">Angola</option>
            <option value="flight_antigua-and-barbuda">
              Antigua and Barbuda
            </option>
            <option value="flight_argentina">Argentina</option>
            <option value="flight_armenia">Armenia</option>
            <option value="flight_australia">Australia</option>
            <option value="flight_austria">Austria</option>
            <option value="flight_azerbaijan">Azerbaijan</option>
            <option value="flight_bahamas">Bahamas</option>
            <option value="flight_bahrain">Bahrain</option>
            <option value="flight_bangladesh">Bangladesh</option>
            <option value="flight_barbados">Barbados</option>
            <option value="flight_belarus">Belarus</option>
            <option value="flight_belgium">Belgium</option>
            <option value="flight_belize">Belize</option>
            <option value="flight_benin">Benin</option>
            <option value="flight_bhutan">Bhutan</option>
            <option value="flight_bolivia">Bolivia</option>
            <option value="flight_bosnia-and-herzegovina">
              Bosnia and Herzegovina
            </option>
            <option value="flight_botswana">Botswana</option>
            <option value="flight_brazil">Brazil</option>
            <option value="flight_brunei">Brunei</option>
            <option value="flight_bulgaria">Bulgaria</option>
            <option value="flight_burkina-faso">Burkina Faso</option>
            <option value="flight_burundi">Burundi</option>
            <option value="flight_cabo-verde">Cabo Verde</option>
            <option value="flight_cambodia">Cambodia</option>
            <option value="flight_cameroon">Cameroon</option>
            <option value="flight_canada">Canada</option>
            <option value="flight_central-african-republic">
              Central African Republic
            </option>
            <option value="flight_chad">Chad</option>
            <option value="flight_chile">Chile</option>
            <option value="flight_china">China</option>
            <option value="flight_colombia">Colombia</option>
            <option value="flight_comoros">Comoros</option>
            <option value="flight_congo">Congo</option>
            <option value="flight_costa-rica">Costa Rica</option>
            <option value="flight_croatia">Croatia</option>
            <option value="flight_cuba">Cuba</option>
            <option value="flight_cyprus">Cyprus</option>
            <option value="flight_czech-republic">Czech Republic</option>
            <option value="flight_democratic-republic-of-the-congo">
              Democratic Republic of the Congo
            </option>
            <option value="flight_denmark">Denmark</option>
            <option value="flight_djibouti">Djibouti</option>
            <option value="flight_dominica">Dominica</option>
            <option value="flight_dominican-republic">
              Dominican Republic
            </option>
            <option value="flight_ecuador">Ecuador</option>
            <option value="flight_egypt">Egypt</option>
            <option value="flight_el-salvador">El Salvador</option>
            <option value="flight_equatorial-guinea">Equatorial Guinea</option>
            <option value="flight_eritrea">Eritrea</option>
            <option value="flight_estonia">Estonia</option>
            <option value="flight_eswatini">Eswatini</option>
            <option value="flight_ethiopia">Ethiopia</option>
            <option value="flight_fiji">Fiji</option>
            <option value="flight_finland">Finland</option>
            <option value="flight_france">France</option>
            <option value="flight_gabon">Gabon</option>
            <option value="flight_gambia">Gambia</option>
            <option value="flight_georgia">Georgia</option>
            <option value="flight_germany">Germany</option>
            <option value="flight_ghana">Ghana</option>
            <option value="flight_greece">Greece</option>
            <option value="flight_grenada">Grenada</option>
            <option value="flight_guatemala">Guatemala</option>
            <option value="flight_guinea">Guinea</option>
            <option value="flight_guinea-bissau">Guinea-Bissau</option>
            <option value="flight_guyana">Guyana</option>
            <option value="flight_haiti">Haiti</option>
            <option value="flight_honduras">Honduras</option>
            <option value="flight_hungary">Hungary</option>
            <option value="flight_iceland">Iceland</option>
            <option value="flight_india">India</option>
            <option value="flight_indonesia">Indonesia</option>
            <option value="flight_iran">Iran</option>
            <option value="flight_iraq">Iraq</option>
            <option value="flight_ireland">Ireland</option>
            <option value="flight_israel">Israel</option>
            <option value="flight_italy">Italy</option>
            <option value="flight_jamaica">Jamaica</option>
            <option value="flight_japan">Japan</option>
            <option value="flight_jordan">Jordan</option>
            <option value="flight_kazakhstan">Kazakhstan</option>
            <option value="flight_kenya">Kenya</option>
            <option value="flight_kiribati">Kiribati</option>
            <option value="flight_korea-north">North Korea</option>
            <option value="flight_korea-south">South Korea</option>
            <option value="flight_kuwait">Kuwait</option>
            <option value="flight_kyrgyzstan">Kyrgyzstan</option>
            <option value="flight_laos">Laos</option>
            <option value="flight_latvia">Latvia</option>
            <option value="flight_lebanon">Lebanon</option>
            <option value="flight_lesotho">Lesotho</option>
            <option value="flight_liberia">Liberia</option>
            <option value="flight_libya">Libya</option>
            <option value="flight_liechtenstein">Liechtenstein</option>
            <option value="flight_lithuania">Lithuania</option>
            <option value="flight_luxembourg">Luxembourg</option>
            <option value="flight_madagascar">Madagascar</option>
            <option value="flight_malawi">Malawi</option>
            <option value="flight_malaysia">Malaysia</option>
            <option value="flight_mali">Mali</option>
            <option value="flight_malta">Malta</option>
            <option value="flight_marocco">Morocco</option>
            <option value="flight_mozambique">Mozambique</option>
            <option value="flight_myanmar">Myanmar</option>
            <option value="flight_namibia">Namibia</option>
            <option value="flight_nauru">Nauru</option>
            <option value="flight_nepal">Nepal</option>
            <option value="flight_netherlands">Netherlands</option>
            <option value="flight_new-zealand">New Zealand</option>
            <option value="flight_nicaragua">Nicaragua</option>
            <option value="flight_niger">Niger</option>
            <option value="flight_nigeria">Nigeria</option>
            <option value="flight_norway">Norway</option>
            <option value="flight_oman">Oman</option>
            <option value="flight_pakistan">Pakistan</option>
            <option value="flight_palestine">Palestine</option>
            <option value="flight_panama">Panama</option>
            <option value="flight_papua-new-guinea">Papua New Guinea</option>
            <option value="flight_paraguay">Paraguay</option>
            <option value="flight_peru">Peru</option>
            <option value="flight_philippines">Philippines</option>
            <option value="flight_poland">Poland</option>
            <option value="flight_portugal">Portugal</option>
            <option value="flight_qatar">Qatar</option>
            <option value="flight_romania">Romania</option>
            <option value="flight_russia">Russia</option>
            <option value="flight_rwanda">Rwanda</option>
            <option value="flight_samoa">Samoa</option>
            <option value="flight_san-marino">San Marino</option>
            <option value="flight_sao-tome-and-principe">
              São Tomé and Príncipe
            </option>
            <option value="flight_saudi-arabia">Saudi Arabia</option>
            <option value="flight_senegal">Senegal</option>
            <option value="flight_serbia">Serbia</option>
            <option value="flight_seychelles">Seychelles</option>
            <option value="flight_sierra-leone">Sierra Leone</option>
            <option value="flight_singapore">Singapore</option>
            <option value="flight_slovakia">Slovakia</option>
            <option value="flight_slovenia">Slovenia</option>
            <option value="flight_solomon-islands">Solomon Islands</option>
            <option value="flight_somalia">Somalia</option>
            <option value="flight_south-africa">South Africa</option>
            <option value="flight_south-sudan">South Sudan</option>
            <option value="flight_spain">Spain</option>
            <option value="flight_sri-lanka">Sri Lanka</option>
            <option value="flight_sudan">Sudan</option>
            <option value="flight_suriname">Suriname</option>
            <option value="flight_sweden">Sweden</option>
            <option value="flight_switzerland">Switzerland</option>
            <option value="flight_tajikistan">Tajikistan</option>
            <option value="flight_tanzania">Tanzania</option>
            <option value="flight_thailand">Thailand</option>
            <option value="flight_timor-leste">Timor-Leste</option>
            <option value="flight_togo">Togo</option>
            <option value="flight_tonga">Tonga</option>
            <option value="flight_trinidad-and-tobago">
              Trinidad and Tobago
            </option>
            <option value="flight_tunisia">Tunisia</option>
            <option value="flight_turkmenistan">Turkmenistan</option>
            <option value="flight_turkey">Turkey</option>
            <option value="flight_tuvalu">Tuvalu</option>
            <option value="flight_uganda">Uganda</option>
            <option value="flight_ukraine">Ukraine</option>
            <option value="flight_united-arab-emirates">
              United Arab Emirates
            </option>
            <option value="flight_united-kingdom">United Kingdom</option>
            <option value="flight_united-states">United States</option>
            <option value="flight_uruguay">Uruguay</option>
            <option value="flight_uzbekistan">Uzbekistan</option>
            <option value="flight_vanuatu">Vanuatu</option>
            <option value="flight_vatican-city">Vatican City</option>
            <option value="flight_venezuela">Venezuela</option>
            <option value="flight_vietnam">Vietnam</option>
            <option value="flight_yemen">Yemen</option>
            <option value="flight_zambia">Zambia</option>
            <option value="flight_zimbabwe">Zimbabwe</option>
          </select>
          <br />
          <br />
          <label htmlFor="message">MESSAGE:</label>
          <textarea
            name="message"
            id="message"
            placeholder="ECRIVEZ VOTRE MESSAGE"
            value={formData.message}
            onChange={handleChange}
            style={{ borderRadius: "7px" }}
          ></textarea>
          <input type="submit" value="Envoyez" className="button" />
        </form>
      </div>

      {showSuccessPopup && (
        <div className="success-popup">
          <p>
            Message envoyé avec succès. Nous vous répondrons dès que possible.
          </p>
        </div>
      )}
    </>
  );
};

//

export const Cruises = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    tel: "",
    codeTel: "+212", // Default value
    message: "",
    pays: "",
  });

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_xwo1nkg",
        "template_173o5pm",
        e.target,
        "u1vV_5wxERJS3ecIC"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          setShowSuccessPopup(true); // Show the popup

          setTimeout(() => {
            setShowSuccessPopup(false); // Hide the popup after 3 seconds
          }, 3000);

          setFormData({
            name: "",
            email: "",
            tel: "",
            codeTel: "+212", // Reset to default
            message: "",
            pays: "",
          });
        },
        (error) => {
          console.error("Email sending failed:", error.text);
          // Optionally set error state here for user feedback
        }
      );
  };

  return (
    <>
      <Head />
      {/* <div>
        <img src={v1} alt="contact" className="contactimg" />
      </div> */}
      <h2 className="contactez" id="contact">
        RESERVEZ
      </h2>
      <div className="contact">
        <form onSubmit={sendEmail} ref={form}>
          <label htmlFor="name">NOM:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="VOTRE NOM COMPLET"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <br />
          <label htmlFor="email">EMAIL:</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="VOTRE EMAIL"
            value={formData.email}
            onChange={handleChange}
          />
          <br />
          <label htmlFor="tel">NUMERO DE TELEPHONE:</label>
          <div style={{ display: "flex", flexDirection: "row", width: "111%" }}>
            <select
              style={{ width: "23%" }}
              name="codeTel"
              value={formData.codeTel}
              onChange={handleChange}
              required
            >
              <option value="+212">(+212) Maroc</option>
              <option value="+213">(+213) Algérie</option>
              <option value="+216">(+216) Tunisie</option>
              <option value="+218">(+218) Libye</option>
              <option value="+20">(+20) Égypte</option>
              <option value="+30">(+30 ) Grèce</option>
              <option value="+31">(+31 ) Pays-Bas</option>
              <option value="+32">(+32 ) Belgique</option>
              <option value="+33">(+33 ) France</option>
              <option value="+34">(+34 ) Espagne</option>
              <option value="+39">(+39 ) Italie</option>
              <option value="+44">(+44 ) Royaume-Uni</option>
              <option value="+49">(+49 ) Allemagne</option>
            </select>
            <input
              type="text"
              id="tel"
              name="tel"
              value={formData.tel}
              pattern="\d*"
              title="Le numéro de téléphone doit contenir uniquement des chiffres"
              onChange={handleChange}
              placeholder="VOTRE NUMERO DE TELEPHONE"
              style={{ width: "67%" }}
              required
            />
          </div>
          <br />
          <label htmlFor="pays">PAYS DE DESTINATION:</label>
          <select
            id="pays"
            name="pays"
            value={formData.pays}
            onChange={handleChange}
            required
          >
            <option value="cruise_albania">Albania</option>
            <option value="cruise_andorra">Andorra</option>
            <option value="cruise_austria">Austria</option>
            <option value="cruise_belarus">Belarus</option>
            <option value="cruise_belgium">Belgium</option>
            <option value="cruise_bosnia-and-herzegovina">
              Bosnia and Herzegovina
            </option>
            <option value="cruise_bulgaria">Bulgaria</option>
            <option value="cruise_croatia">Croatia</option>
            <option value="cruise_cyprus">Cyprus</option>
            <option value="cruise_czech-republic">Czech Republic</option>
            <option value="cruise_denmark">Denmark</option>
            <option value="cruise_estonia">Estonia</option>
            <option value="cruise_finland">Finland</option>
            <option value="cruise_france">France</option>
            <option value="cruise_georgia">Georgia</option>
            <option value="cruise_germany">Germany</option>
            <option value="cruise_greece">Greece</option>
            <option value="cruise_hungary">Hungary</option>
            <option value="cruise_iceland">Iceland</option>
            <option value="cruise_ireland">Ireland</option>
            <option value="cruise_italy">Italy</option>
            <option value="cruise_latvia">Latvia</option>
            <option value="cruise_lithuania">Lithuania</option>
            <option value="cruise_luxembourg">Luxembourg</option>
            <option value="cruise_malta">Malta</option>
            <option value="cruise_moldova">Moldova</option>
            <option value="cruise_netherlands">Netherlands</option>
            <option value="cruise_norway">Norway</option>
            <option value="cruise_poland">Poland</option>
            <option value="cruise_portugal">Portugal</option>
            <option value="cruise_romania">Romania</option>
            <option value="cruise_russia">Russia</option>
            <option value="cruise_san-marino">San Marino</option>
            <option value="cruise_slovakia">Slovakia</option>
            <option value="cruise_slovenia">Slovenia</option>
            <option value="cruise_spain">Spain</option>
            <option value="cruise_sweden">Sweden</option>
            <option value="cruise_switzerland">Switzerland</option>
            <option value="cruise_ukraine">Ukraine</option>
            <option value="cruise_united-kingdom">United Kingdom</option>

            <option value="cruise_afghanistan">Afghanistan</option>
            <option value="cruise_armenia">Armenia</option>
            <option value="cruise_azerbaijan">Azerbaijan</option>
            <option value="cruise_bahrain">Bahrain</option>
            <option value="cruise_bangladesh">Bangladesh</option>
            <option value="cruise_bhutan">Bhutan</option>
            <option value="cruise_cambodia">Cambodia</option>
            <option value="cruise_china">China</option>
            <option value="cruise_georgia">Georgia</option>
            <option value="cruise_india">India</option>
            <option value="cruise_indonesia">Indonesia</option>
            <option value="cruise_iran">Iran</option>
            <option value="cruise_iraq">Iraq</option>
            <option value="cruise_israel">Israel</option>
            <option value="cruise_japan">Japan</option>
            <option value="cruise_jordan">Jordan</option>
            <option value="cruise_kazakhstan">Kazakhstan</option>
            <option value="cruise_kuwait">Kuwait</option>
            <option value="cruise_kyrgyzstan">Kyrgyzstan</option>
            <option value="cruise_laos">Laos</option>
            <option value="cruise_lebanon">Lebanon</option>
            <option value="cruise_malaysia">Malaysia</option>
            <option value="cruise_myanmar">Myanmar</option>
            <option value="cruise_nepal">Nepal</option>
            <option value="cruise_oman">Oman</option>
            <option value="cruise_pakistan">Pakistan</option>
            <option value="cruise_palestine">Palestine</option>
            <option value="cruise_philippines">Philippines</option>
            <option value="cruise_qatar">Qatar</option>
            <option value="cruise_saudi-arabia">Saudi Arabia</option>
            <option value="cruise_singapore">Singapore</option>
            <option value="cruise_south-korea">South Korea</option>
            <option value="cruise_sri-lanka">Sri Lanka</option>
            <option value="cruise_tajikistan">Tajikistan</option>
            <option value="cruise_thailand">Thailand</option>
            <option value="cruise_turkmenistan">Turkmenistan</option>
            <option value="cruise_united-arab-emirates">
              United Arab Emirates
            </option>
            <option value="cruise_uzbekistan">Uzbekistan</option>
            <option value="cruise_vietnam">Vietnam</option>
            <option value="cruise_yemen">Yemen</option>

            <option value="cruise_antigua-and-barbuda">
              Antigua and Barbuda
            </option>
            <option value="cruise_argentina">Argentina</option>
            <option value="cruise_bahamas">Bahamas</option>
            <option value="cruise_barbados">Barbados</option>
            <option value="cruise_belize">Belize</option>
            <option value="cruise_bolivia">Bolivia</option>
            <option value="cruise_brazil">Brazil</option>
            <option value="cruise_canada">Canada</option>
            <option value="cruise_chile">Chile</option>
            <option value="cruise_colombia">Colombia</option>
            <option value="cruise_cuba">Cuba</option>
            <option value="cruise_dominica">Dominica</option>
            <option value="cruise_dominican-republic">
              Dominican Republic
            </option>
            <option value="cruise_ecuador">Ecuador</option>
            <option value="cruise_el-salvador">El Salvador</option>
            <option value="cruise_guatemala">Guatemala</option>
            <option value="cruise_guyana">Guyana</option>
            <option value="cruise_haiti">Haiti</option>
            <option value="cruise_honduras">Honduras</option>
            <option value="cruise_jamaica">Jamaica</option>
            <option value="cruise_mexico">Mexico</option>
            <option value="cruise_nicaragua">Nicaragua</option>
            <option value="cruise_panama">Panama</option>
            <option value="cruise_paraguay">Paraguay</option>
            <option value="cruise_peru">Peru</option>
            <option value="cruise_suriname">Suriname</option>
            <option value="cruise_trinidad-and-tobago">
              Trinidad and Tobago
            </option>
            <option value="cruise_united-states">United States</option>
          </select>
          <br />
          <br />
          <label htmlFor="message">MESSAGE:</label>
          <textarea
            name="message"
            id="message"
            placeholder="ECRIVEZ VOTRE MESSAGE"
            value={formData.message}
            onChange={handleChange}
            style={{ borderRadius: "7px" }}
          ></textarea>
          <input type="submit" value="Envoyez" className="button" />
        </form>
      </div>

      {showSuccessPopup && (
        <div className="success-popup">
          <p>
            Message envoyé avec succès. Nous vous répondrons dès que possible.
          </p>
        </div>
      )}
    </>
  );
};
